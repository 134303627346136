<template>
  <div v-if="isSysAdmin"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
    <div class="w-full max-w-6xl bg-white rounded-lg shadow-xl overflow-hidden flex flex-col">
      <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <img src="@/assets/img/logo.svg" alt="Aignis" class="h-6 w-auto" />
          <h3 class="text-xl font-bold">Planes de precios</h3>
        </div>
        <button @click="emit('close')"
          class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
          <XIcon class="h-6 w-6" />
        </button>
      </div>
      <div class="p-6 bg-gray-50 overflow-y-auto" style="max-height: 70vh;">
        <!-- Botón para crear plan -->
        <div class="mb-4 flex justify-end">
          <button @click="showPlanForm = true"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md">
            Crear Plan
          </button>
        </div>

        <!-- Lista de planes -->
        <div class="mb-8 overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col"
                  class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                </th>
                <th v-for="plan in pricingPlans" :key="plan.id" scope="col"
                  class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                  {{ plan.name }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Precio</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ plan.price }} &euro;
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Usuarios Máx.</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ plan.maxUsers }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Uso Máximo de Disco</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ formatBytes(plan.maxDiskUsageInBytes) }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Tokens de Chat por Mes
                </th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ formatNumber(plan.maxTokensPerMonth) }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Tokens de Embedding por
                  Mes</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ formatNumber(plan.maxEmbeddingTokensPerMonth) }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Bases de datos</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ formatNumber(plan.maxDatabaseConnections) }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Proveedor IA</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ plan.aiProviderConfiguration?.name || 'No asignado' }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Público</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ plan.public ? 'Sí' : 'No' }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500">Contratable</th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {{ plan.contractable ? 'Sí' : 'No' }}
                </td>
              </tr>
              <tr>
                <th class="px-6 py-3 bg-indigo-50 text-left text-xs font-medium text-gray-500"></th>
                <td v-for="plan in pricingPlans" :key="plan.id"
                  class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-right">
                  <button @click="editPlan(plan)" class="text-indigo-600 hover:text-indigo-900 mr-2">
                    <Edit2Icon class="h-5 w-5" />
                  </button>
                  <button @click="deletePlan(plan.id)" class="text-red-600 hover:text-red-900">
                    <TrashIcon class="h-5 w-5" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Formulario para crear/editar plan -->
    <div v-if="showPlanForm"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
      <div class="w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden">
        <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
          <h3 class="text-xl font-bold">{{ editingPlan ? 'Editar' : 'Crear' }} Plan de Precios</h3>
          <button @click="closePlanForm"
            class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
            <XIcon class="h-6 w-6" />
          </button>
        </div>
        <div class="p-6 bg-gray-50">
          <form @submit.prevent="submitPlanForm" class="space-y-4">
            <div class="relative">
              <label for="planName" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Nombre del Plan
              </label>
              <input type="text" id="planName" v-model="planForm.name"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Nombre del Plan" required />
            </div>
            <div class="relative">
              <label for="planPrice" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Precio
              </label>
              <input type="number" id="planPrice" v-model="planForm.price"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Precio" required step="0.01" />
            </div>
            <div class="relative">
              <label for="planMaxUsers" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Usuarios Máximos
              </label>
              <input type="number" id="planMaxUsers" v-model="planForm.maxUsers"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Usuarios Máximos" required />
            </div>
            <div class="relative">
              <label for="planMaxDiskUsage"
                class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Uso Máximo de Disco (bytes)
              </label>
              <input type="number" id="planMaxDiskUsage" v-model="planForm.maxDiskUsageInBytes"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Uso Máximo de Disco" required />
            </div>
            <div class="relative">
              <label for="planMaxTokens" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Tokens Máximos por Mes
              </label>
              <input type="number" id="planMaxTokens" v-model="planForm.maxTokensPerMonth"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Tokens Máximos por Mes" required />
            </div>
            <div class="relative">
              <label for="planMaxEmbeddingTokens"
                class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Tokens de Embedding Máximos por Mes
              </label>
              <input type="number" id="planMaxEmbeddingTokens" v-model="planForm.maxEmbeddingTokensPerMonth"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Tokens de Embedding Máximos por Mes" required />
            </div>
            <div class="relative">
              <label for="maxDatabaseConnections"
                class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Bases de datos
              </label>
              <input type="number" id="maxDatabaseConnections" v-model="planForm.maxDatabaseConnections"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                placeholder="Conexiones a bases de datos" required />
            </div>
            <div class="relative">
              <label for="planAIProvider" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                Proveedor IA
              </label>
              <select id="planAIProvider" v-model="planForm.aiProviderConfigurationId"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500">
                <option :value="null">Sin proveedor IA</option>
                <option v-for="provider in aiProviders" :key="provider.id" :value="provider.id">
                  {{ provider.name }}
                </option>
              </select>
            </div>
            <div class="flex items-center">
              <input type="checkbox" id="planPublic" v-model="planForm.public" class="mr-2">
              <label for="planPublic" class="text-sm font-medium text-gray-700">Público</label>
            </div>
            <div class="flex items-center">
              <input type="checkbox" id="planContractable" v-model="planForm.contractable" class="mr-2">
              <label for="planContractable" class="text-sm font-medium text-gray-700">Contratable</label>
            </div>
            <div class="flex items-center justify-center pt-4">
              <button type="submit"
                class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                {{ editingPlan ? 'Actualizar' : 'Crear' }} Plan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, onMounted, defineEmits, computed } from 'vue';
import { getCurrentUserRoles } from '../utils/auth';
import { XIcon, Edit2Icon, TrashIcon } from 'lucide-vue-next';
import { getPricingPlanList, createPricingPlan, updatePricingPlan, deletePricingPlan, getAIProviderList } from '../services/api';
import { useHead } from '@vueuse/head'
import { useNotification } from '@/plugins/notificationPlugin';

const isSysAdmin = computed(() => {
  const roles = getCurrentUserRoles();
  return roles.includes('SysAdmin');
});

const { showSuccess, showError } = useNotification();

useHead({
  title: 'Gestión de Planes de Precios - Aignis',
  meta: [
    {
      name: 'description',
      content: 'Administra los planes de precios en Aignis.'
    }
  ]
});

const emit = defineEmits(['close']);

const pricingPlans = ref([]);
const aiProviders = ref([]);
const showPlanForm = ref(false);
const editingPlan = ref(false);
const planForm = ref({
  id: null,
  name: '',
  price: 0,
  maxUsers: 0,
  maxDiskUsageInBytes: 0,
  maxTokensPerMonth: 0,
  maxEmbeddingTokensPerMonth: 0,
  maxDatabaseConnections: 0,
  aiProviderConfigurationId: null,
  public: false,
  contractable: false 
});

const fetchPricingPlans = async () => {
  try {
    const plans = await getPricingPlanList();
    pricingPlans.value = plans.data;
  } catch (error) {
    showError(error.detail || 'Error al cargar los planes de precios. Por favor, intente de nuevo.');
  }
};

const fetchAIProviders = async () => {
  try {
    const response = await getAIProviderList();
    aiProviders.value = response.data;
  } catch (error) {
    
    showError(error.detail || 'Error al cargar los proveedores IA. Por favor, intente de nuevo.');
  }
};

const submitPlanForm = async () => {
  try {
    if (editingPlan.value) {
      await updatePricingPlan(planForm.value.id, planForm.value);
    } else {
      await createPricingPlan(planForm.value);
    }
    showSuccess(`Plan ${editingPlan.value ? 'actualizado' : 'creado'} correctamente`);
    closePlanForm();
    await fetchPricingPlans();
  } catch (error) {
    showError(error.detail || `Error al ${editingPlan.value ? 'actualizar' : 'crear'} el plan. Por favor, intente de nuevo.`);
  }
};

const editPlan = (plan) => {
  planForm.value = {
    id: plan.id,
    name: plan.name,
    price: plan.price,
    maxUsers: plan.maxUsers,
    maxDiskUsageInBytes: plan.maxDiskUsageInBytes,
    maxTokensPerMonth: plan.maxTokensPerMonth,
    maxEmbeddingTokensPerMonth: plan.maxEmbeddingTokensPerMonth,
    maxDatabaseConnections: plan.maxDatabaseConnections,
    aiProviderConfigurationId: plan.aiProviderConfiguration ? plan.aiProviderConfiguration.id : null,
    public: plan.public,
    contractable: plan.contractable
  };
  editingPlan.value = true;
  showPlanForm.value = true;
};

const deletePlan = async (planId) => {
  if (confirm('¿Estás seguro de que quieres eliminar este plan?')) {
    try {
      const response = await deletePricingPlan(planId);
      showSuccess(response.detail || 'Plan eliminado correctamente');
      await fetchPricingPlans();
    } catch (error) {
      console.log(error);
      showError(error.detail || 'Error al eliminar el plan. Por favor, intente de nuevo.');
    }
  }
};

const closePlanForm = () => {
  showPlanForm.value = false;
  editingPlan.value = false;
  planForm.value = {
    id: null,
    name: '',
    price: 0,
    maxUsers: 0,
    maxDiskUsageInBytes: 0,
    maxTokensPerMonth: 0,
    maxEmbeddingTokensPerMonth: 0,
    maxDatabaseConnections: 0,
    aiProviderConfigurationId: null
  };
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

onMounted(async () => {
  await fetchPricingPlans();
  await fetchAIProviders();
});
</script>